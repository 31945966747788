import request from '@/utils/request'

// 获取所有姓氏
export const getAllName = () => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/surname-culture/surname-list'
    })
}

// 获取单个姓氏
export const getSingleName = params => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/surname-culture/surname-item',
        params
    })
}

// 迁徙记录
export const getMigrate = params => {
    return request({
        method: 'GET',
        // url: '/knowledgeshow/magrate-appservice/genealogy-list',
        // url: '/knowledgeshow/magrate-appservice/genealogy-list-async-new',
        url: '/knowledgeshow/magrate-appservice/personal-mIgrate-infos-list',
        params
    })
}

// 全文检索
export const searchAll = data => {
    return request({
        method: 'POST',
        url: '/knowledgeshow/search/search-all',
        data: {
            ...data,
            isNoPage: true,
            indexType: ''
        }
    })
}

// 翻页
export const BeforeOrNext = data => {
    return request({
        method: 'POST',
        url: `/knowledgeshow/magrate-appservice/${data.id}/befor-or-next`,
        params: {
            opType: data.opType
        }
    })
}

// 获取详情
export const getDetails = id => {
    return request({
        method: 'GET',
        url: `/knowledgeshow/magrate-appservice/${id}/book-detail-by-id`,
    })
}
