<template>
  <div id="map"></div>
</template>
  <script>
import { loadModules } from "esri-loader";
import echarts from "../../utils/echarts3Extent.js";

export default {
  props: {
    allPointsList: {
      type: Object,
      default: {},
    },
    migrationPointsList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      map: null,
      view: null,
      bdLayerType: "st",
    };
  },

  mounted() {
    this.init();
  },
  methods: {
    // 初始化map
    async init(status = "") {
      // 加载 ArcGIS API for JavaScript 模块
      const [Map, MapView, TileLayer, WebTileLayer] = await loadModules(
        [
          "esri/Map",
          "esri/views/MapView",
          // "esri/layers/GraphicsLayer",
          // "esri/Graphic",
          "esri/layers/TileLayer",
          "esri/layers/WebTileLayer",
          "dojo/domReady!",
        ],
        {
          url: "https://js.arcgis.com/4.8/",
          css: "https://js.arcgis.com/4.8/esri/themes/light/main.css",
        }
      );

      // let transportationLayer = new TileLayer({
      //   // url: "https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetGray/MapServer",
      //   // url: "https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineCommunity/MapServer",
      //   // url: "http://map.geoq.cn/arcgis/rest/services/ChinaOnlineStreetPurplishBlue/MapServer",
      //   url: "http://map.geoq.cn/arcgis/rest/services/ChinaOnlineStreetWarm/MapServer",

      //   //这边使用的是国内服务器上的中国地图
      //   id: "streets",
      // });

      // 创建地图
      this.map = new Map({
        // basemap: "osm",
        basemap: "",
        // layers: [transportationLayer],
        // ground: "world-elevation"
      });

      // 创建地图视图
      // SceneView 3D
      // MapView 2D
      this.view = new MapView({
        container: "map",
        map: this.map,
        center: [119.302903, 26.080553],
        zoom: 6,
      });

      this.view.constraints = {
        maxZoom: 18, //最大空间等级
        minZoom: 3, //最小空间等级
      };

      this.map.layers.removeAll();

      // 天地图
      // let tdtLayerObj = this.loadTDTMapLayer(WebTileLayer);
      // this.map.layers.add(tdtLayerObj.tdtMapLayer);
      // this.map.layers.add(tdtLayerObj.tdtMapTextLayer);

      // 高德地图
      let gdLayer = this.loadGdMapLayer(WebTileLayer);
      this.map.layers.add(gdLayer.gdMapLayer);
      this.map.layers.add(gdLayer.gdMapTextLayer);

      // this.view.goTo({
      //   center: [113.24445311120809, 23.137721894973083],
      //   zoom: 13,
      // });

      this.view.when(() => {
        // if (status === "all") {
        // this.allInitChat();
        // } else {
        this.initChat();
        // }
      });
    },
    async allInitChat() {
      let convertData = (data) => {
        let res = [];
        for (let i = 0; i < data.length; i++) {
          let dataItem = data[i];
          let fromCoord = this.allPointsList[dataItem[0].name];
          let toCoord = this.allPointsList[dataItem[1].name];
          if (fromCoord && toCoord) {
            res.push({
              // fromName: dataItem[0].name,
              // toName: dataItem[1].name,
              // coords: [fromCoord, toCoord],
            });
            res.push([
              {
                coord: fromCoord,
              },
              {
                coord: toCoord,
              },
            ]);
          }
        }
        return res;
      };
      // let planePath =
      //   "path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z";

      let symCanv = document.createElement("canvas");
      let symCtx = symCanv.getContext("2d");
      let symHeight = 20,
        symWidth = 4;
      symCanv.height = symHeight;
      symCanv.width = symWidth;
      let linePattern = symCtx.createLinearGradient(0, 0, 0, symHeight);
      linePattern.addColorStop(0, "rgba(255,255,255,.4)");
      linePattern.addColorStop(1, "rgba(255,255,255,0)");
      symCtx.strokeStyle = linePattern;
      symCtx.lineWidth = symWidth;
      symCtx.beginPath();
      symCtx.moveTo(symWidth / 2, 0);
      symCtx.lineTo(symWidth / 2, symHeight);
      symCtx.stroke();
      let lineSymbol = symCanv.toDataURL();

      let color = ["#a6c84c", "#ffa022", "#46bee9"];
      let series = [];
      this.migrationPointsList.forEach((item, i) => {
        const that = this;
        series.push(
          // {
          //   name: item[0],
          //   type: "effectScatter",
          //   coordinateSystem: "geo",
          //   zlevel: 2,
          //   rippleEffect: {
          //     period: 4, //动画时间，值越小速度越快
          //     brushType: "stroke", //波纹绘制方式 stroke, fill
          //     scale: 4, //波纹圆环最大限制，值越大波纹越大
          //     trailLength: 0,
          //   },
          //   label: {
          //     normal: {
          //       show: true,
          //       position: "right",
          //       formatter: "{b}",
          //     },
          //   },
          //   symbolSize: function (val) {
          //     return val[2] / 8 + 4; //圆环大小
          //   },
          //   itemStyle: {
          //     normal: {
          //       color: color[i],
          //     },
          //   },
          //   data: [
          //     {
          //       // name: item[0],
          //       value: that.allPointsList[item[0]].concat([10]),
          //     },
          //   ],
          // },
          {
            name: item[0],
            type: "lines",
            zlevel: 1,
            effect: {
              show: true,
              period: 6,
              trailLength: 0.6,
              color: "#fff",
              symbolSize: 3,
            },
            lineStyle: {
              normal: {
                color: color[i % 3 === 2 ? 2 : i % 3],
                width: 0,
                curveness: 0.2,
              },
            },
            data: convertData(item[1]),
          },
          // 飞线
          {
            name: item[0],
            type: "lines",
            zlevel: 2,
            effect: {
              show: false,
              period: 6,
              trailLength: 0.7,
              symbol: planePath,
              symbolSize: 15,
              // symbol: lineSymbol,
              // ymbolSize: [symWidth, symHeight],
              // symbol: "arrow", //箭头图标
              // symbolSize: 8, //图标大小
            },
            lineStyle: {
              normal: {
                color: color[i % 3 === 2 ? 2 : i % 3],
                width: 1,
                opacity: 0.4,
                curveness: 0.2,
              },
            },
            data: convertData(item[1]),
          }

          // 点
          // {
          //   name: item[0],
          //   type: "effectScatter",
          //   coordinateSystem: "geo",
          //   zlevel: 2,
          //   rippleEffect: {
          //     period: 4, //动画时间，值越小速度越快
          //     brushType: "stroke", //波纹绘制方式 stroke, fill
          //     scale: 4, //波纹圆环最大限制，值越大波纹越大
          //     trailLength: 0,
          //   },
          //   label: {
          //     normal: {
          //       show: true,
          //       position: "right",
          //       formatter: "{b}",
          //     },
          //   },
          //   symbolSize: function (val) {
          //     return val[2] / 8 + 4; //圆环大小
          //   },
          //   itemStyle: {
          //     normal: {
          //       color: color[i],
          //     },
          //   },
          //   data: item[1].map((dataItem) => {
          //     return {
          //       name: dataItem[1].name,
          //       value: that.allPointsList[dataItem[1].name].concat([
          //         dataItem[1].value,
          //       ]),
          //     };
          //   }),
          // }
        );
      });

      let option = {
        title: {
          text: "迁徙轨迹图",
          left: "center",
          textStyle: {
            color: "#fff",
          },
        },
        geo: {
          map: "",
          label: {
            emphasis: {
              show: false,
            },
          },
          animationDurationUpdate: 0,
          roam: true,
          itemStyle: {
            normal: {
              areaColor: "#323c48",
              borderColor: "#404a59",
            },
            emphasis: {
              areaColor: "#2a333d",
            },
          },
        },
        series: series,
      };
      let options = [];
      options.push(option);

      const [EchartsLayer] = await loadModules(
        ["http://192.168.1.26:1901/EchartsLayerV3.0.js"],
        { url: "https://js.arcgis.com/4.8/dojo/dojo.js" }
      );

      let chart = new EchartsLayer(this.view, echarts);
      let chartsContainer = chart.getEchartsContainer();
      let myChart = chart.initECharts(chartsContainer);
      myChart.setOption(option, true);
    },
    async initChat() {
      let convertData = (data) => {
        let res = [];
        for (let i = 0; i < data.length; i++) {
          let dataItem = data[i];
          let fromCoord = this.allPointsList[dataItem[0].name];
          let toCoord = this.allPointsList[dataItem[1].name];
          if (fromCoord && toCoord) {
            // res.push({
            //   fromName: dataItem[0].name,
            //   toName: dataItem[1].name,
            //   coords: [fromCoord, toCoord],
            // });
            res.push([
              {
                coord: fromCoord,
              },
              {
                coord: toCoord,
              },
            ]);
          }
        }
        return res;
      };

      // let symCanv = document.createElement("canvas");
      // let symCtx = symCanv.getContext("2d");
      // let symHeight = 20,
      //   symWidth = 4;
      // symCanv.height = symHeight;
      // symCanv.width = symWidth;
      // let linePattern = symCtx.createLinearGradient(0, 0, 0, symHeight);
      // linePattern.addColorStop(0, "rgba(255,255,255,.4)");
      // linePattern.addColorStop(1, "rgba(255,255,255,0)");
      // symCtx.strokeStyle = linePattern;
      // symCtx.lineWidth = symWidth;
      // symCtx.beginPath();
      // symCtx.moveTo(symWidth / 2, 0);
      // symCtx.lineTo(symWidth / 2, symHeight);
      // symCtx.stroke();
      // let lineSymbol = symCanv.toDataURL();

      let color = ["#a6c84c", "#ffa022", "#46bee9"];
      let series = [];
      this.migrationPointsList.forEach((item, i) => {
        const that = this;
        item[1][0].forEach((x, index) => {
          series.push({
            name: x,
            type: "effectScatter",
            coordinateSystem: "geo",
            zlevel: 2,
            rippleEffect: {
              period: 4, //动画时间，值越小速度越快
              brushType: "stroke", //波纹绘制方式 stroke, fill
              scale: 4, //波纹圆环最大限制，值越大波纹越大
              trailLength: 0,
            },
            label: {
              normal: {
                show: true,
                position: "right",
                formatter: "{b}",
              },
            },
            symbolSize: function (val) {
              return val[2] / 8 + 10; //圆环大小
            },
            itemStyle: {
              normal: {
                color: color[i % 3 === 2 ? 2 : i % 3],
              },
            },
            data: [
              {
                name: x.name,
                value: x.value
                  ? that.allPointsList[x.name].concat(x.value)
                  : that.allPointsList[x.name].concat(10),
              },
            ],
          });
          if (index !== 0) {
            if (item[1][0][index - 1].moveType === 0) {
              series.push(
                // 线
                {
                  name: x.name,
                  type: "lines",
                  zlevel: 2,
                  // effect: {
                  //   show: false,
                  //   period: 6,
                  //   trailLength: 0.6,
                  // },
                  lineStyle: {
                    normal: {
                      color: "#fff",
                      width: 2,
                      opacity: 0.4,
                      // curveness: 0.5,
                    },
                  },
                  data: convertData([[item[1][0][index - 1], x]]),
                },
                // 拖尾特效 马车
                {
                  name: x.name,
                  type: "lines",
                  zlevel: 1,
                  effect: {
                    animation: false,
                    show: true,
                    period: 8,
                    trailLength: 0.6,
                    color: "#fff",
                    symbol: "line",
                    symbolSize: 3,

                    // animation: false,
                    // show: true,
                    // period: 8,
                    // trailLength: 0,
                    // color: "#fff",
                    // symbol: "image://https://xungen.guanshutangocr.com/car.png",
                    // symbolSize: 50,
                  },
                  lineStyle: {
                    normal: {
                      color: "#fff",
                      width: 0,
                      // curveness: 0.5,
                    },
                  },
                  data: convertData([[item[1][0][index - 1], x]]),
                }
              );
            } else {
              // 拖尾特效 船舶
              series.push(
                // 线
                {
                  name: x.name,
                  type: "lines",
                  zlevel: 2,
                  // effect: {
                  //   show: false,
                  //   period: 6,
                  //   trailLength: 0.6,
                  // },
                  lineStyle: {
                    normal: {
                      color: "#fff",
                      width: 2,
                      opacity: 0.4,
                      // curveness: 0.5,
                    },
                  },
                  data: convertData([[item[1][0][index - 1], x]]),
                },
                {
                  name: x.name,
                  type: "lines",
                  zlevel: 1,
                  effect: {
                    animation: false,
                    show: true,
                    period: 8,
                    trailLength: 0.6,
                    color: "#fff",
                    symbol: "line",
                    symbolSize: 3,

                    // animation: false,
                    // show: true,
                    // period: 8,
                    // trailLength: 0,
                    // color: "#fff",
                    // symbol:
                    //   "image://https://xungen.guanshutangocr.com/boat.png",
                    // symbolSize: 50,
                  },
                  lineStyle: {
                    normal: {
                      color: "#fff",
                      width: 0,
                      // curveness: 0.5,
                    },
                  },
                  data: convertData([[item[1][0][index - 1], x]]),
                }
              );
            }
          }
        });
      });

      let option = {
        title: {
          text: "迁徙轨迹图",
          left: "center",
          textStyle: {
            color: "#fff",
          },
        },
        geo: {
          map: "lns",
          label: {
            emphasis: {
              show: false,
            },
          },
          roam: true,
          itemStyle: {
            normal: {
              areaColor: "#323c48",
              borderColor: "#404a59",
            },
            emphasis: {
              areaColor: "#2a333d",
            },
          },
        },
        series: series,
      };
      let options = [];
      options.push(option);
      let url = `${window.location.protocol}//${window.location.host}/`;
      const [EchartsLayer] = await loadModules([`${url}EchartsLayerV3.0.js`], {
        url: "https://js.arcgis.com/4.8/dojo/dojo.js",
      });

      let chart = new EchartsLayer(this.view, echarts);
      let chartsContainer = chart.getEchartsContainer();
      let myChart = chart.initECharts(chartsContainer);
      myChart.setOption(option, true);
    },
    loadTDTMapLayer(WebTileLayer) {
      let tdtMapLayer = new WebTileLayer({
        id: "tianditu",
        urlTemplate: "",
        name: "天地图",
      });
      let tdtMapTextLayer = new WebTileLayer({
        id: "tiandituText",
        urlTemplate: "",
        name: "天地图标注",
      });
      let ak = "f631ee4e9c0294f1a44c88fa17ac2de2";
      tdtMapLayer.urlTemplate =
        "http://t0.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={level}&TILEROW={row}&TILECOL={col}&tk=" +
        ak;
      tdtMapTextLayer.urlTemplate =
        "http://t0.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={level}&TILEROW={row}&TILECOL={col}&tk=" +
        ak;
      return { tdtMapLayer, tdtMapTextLayer };
    },
    // 高德底图
    loadGdMapLayer(WebTileLayer) {
      let gdMapLayer = new WebTileLayer({
        id: "gaode",
        urlTemplate: "",
        name: "高德地图",
      });
      let gdMapTextLayer = new WebTileLayer({
        id: "gaodeText",
        urlTemplate: "",
        name: "高德标注",
      });
      gdMapLayer.urlTemplate =
        "https://webst01.is.autonavi.com/appmaptile?style=6&x={col}&y={row}&z={level}";
      gdMapTextLayer.urlTemplate =
        "http://webst02.is.autonavi.com/appmaptile?x={col}&y={row}&z={level}&lang=zh_cn&size=1&scale=1&style=8";
      return { gdMapLayer, gdMapTextLayer };
    },
  },
};
</script>
  
<style lang="scss" scoped>
#map {
  height: calc(100vh - 65px);
}
::v-deep .esri-attribution__sources,
.esri-interactive {
  display: none;
}
::v-deep .esri-attribution__powered-by {
  display: none;
}
</style>
    